import * as React from "react";

import Layout from "../components/Layout";
import SetPasswordForm from "../components/SetPasswordForm";

const SetPassword = ()=> {
  const params = typeof window !== `undefined` ? new URLSearchParams(window.location.search) : null;
  const resetKey = typeof window !== `undefined` ? params.get('key') : '';
  const login = typeof window !== `undefined` ? params.get('login') : '';

  return (
    <Layout pageTitle="Set Password" pageSlug="set-password">
      <h1>Set Password</h1>
      <SetPasswordForm resetKey={resetKey} login={login} />
    </Layout>
  )
}

export default SetPassword;
